
import React, { useState } from 'react';
import Header from "./componentes/header/header.jsx";

import './App.css';

const App = () => {
  const [lotteryResults, setLotteryResults] = useState([]);

  const handleLotteryResultsChange = (results) => {
    setLotteryResults(results);
  };

  return (
    <div className="App">
      <Header onLotteryResultsChange={handleLotteryResultsChange} />

    </div>
  );
}

export default App;
