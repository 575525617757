import "./footer.css";
import React from "react";

const footer = () => {
  return (
    <div className="footer">
      <div className="nm">
        <a href="#inicio" className="nome2">
          Inicio
        </a>
        <a href="#resultados" className="nome2">
          Resultados
        </a>
      </div>

      <div className="informacoes"></div>
      <div className="inf2">© 2023 Loto da sorte</div>
    </div>
  );
};
export default footer;
