import { useEffect, useState} from 'react';
import './tabela.css';
const Tabela = ({ lotteryResults }) => {
    const [info, setInfo] = useState([]);

   
    useEffect(() => {
        setInfo(lotteryResults);
    }, [lotteryResults]);
    return (
        <div className="container">
            <div className="innerContainer">
                <div className="slider-resultados">
                    {info.map((infos, key) => (
                        <div className="box" key={key}>
                            <div className="box-titulo">
                                <div className="lottery_name">{infos.lottery_name}</div>
                                <div className="lottery_date">{infos.lottery_date}</div>
                                <div className="lottery_type">{infos.lottery_type}</div>
                            </div>
                            <div className="conteudo">
                            {infos.result.map((result, index) => (
                            <div className='linha' key={index}>
                                {result.posicao}
                                <div className='item-negrito'>{result.numero}</div>
                                <div className="item">{result.grupo}</div>
                                <div className="item">{result.bicho}</div>
                            </div>
                            ))}
                                {/* Restante do código do loop */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tabela;
