import React, { useState, useEffect } from "react";
import axios from "axios";
import Tabela from "../tabela/tabela";
import Footer from "../footer/footer";
import nome from "../../imagens/nomeloto.png";
import frase from "../../imagens/FRASE.png";
import Boneco2 from "../../imagens/img.png";
import menu from "../../imagens/menuicon-removebg-preview.png";

import "./header.css";

const EstadoFilter = ({ onLotteryResultsChange }) => {
  const [selectedEstado, setSelectedEstado] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lotteryResults, setLotteryResults] = useState([]);

  const handleEstadoChange = (event) => {
    setSelectedEstado(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const fetchData = async (url) => {
    try {
      const response = await axios.get(url);
      const data = response.data;
      setLotteryResults(data);
      onLotteryResultsChange(data); // Atualiza os resultados da loteria no componente pai
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDefaultData = async () => {
    const defaultUrl =
      "https://api.ojogodosbichos.com/api/v2/check_results_extra/";
    await fetchData(defaultUrl);
  };

  // Chamar a função fetchDefaultData apenas uma vez
  useEffect(() => {
    fetchDefaultData();
  }, []);

  const handleSearch = async () => {
    try {
      let url = "https://api.ojogodosbichos.com/api/v2/check_results_extra/";

      if (selectedEstado) {
        url += `?uf=${selectedEstado}`;

        if (startDate && endDate) {
          url += `&start_date=${startDate}&end_date=${endDate}`;
        } else if (startDate) {
          url += `&start_date=${startDate}`;
        }
      }
      fetchData(url);
      console.log(url);
    } catch (error) {
      console.error(error);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <a name="inicio" />
      <div>
        <header className="header">
          <img alt="nome" src={nome} className="nomeimg" />

          <div id="link">
            <a href="#inicio" className="nome">
              Inicio
            </a>
            <a href="#resultados" className="nome">
              Resultados
            </a>
          </div>
        </header>

        <div className="select-est">
          <div className="txt">
            <img alt="frase" src={frase} className="frase" />
            <p className="frasedois">
              Aqui você tem os todos os resultados dos<br></br> jogos com a
              melhor qualidade.
            </p>

            <Tabela lotteryResults={lotteryResults} />

            <div className="filtros">
              <h4 className="ft_est">Filtro de Estados</h4>
              <select
                value={selectedEstado}
                onChange={handleEstadoChange}
                className="sl_es"
              >
                <option value="">Selecione um estado</option>
                <option value="MG">Minas Gerais</option>
                <option value="PE">Pernambuco</option>
                <option value="CE">Ceará</option>
                <option value="BA">Bahia</option>
                <option value="PB">Paraíba</option>
                <option value="PA">Pará</option>
                <option value="PR">Paraná</option>
                <option value="RS">Rio Grande do Sul</option>
              </select>
              <div className="div-date">
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  className="date"
                />
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  className="date"
                />
              </div>
              <button className="btn-filtro" onClick={handleSearch}>
                Pesquisar
              </button>

              {selectedEstado && (
                <p>
                  O estado selecionado é:{" "}
                  <span className="estado-selecionado">{selectedEstado}</span>
                </p>
              )}
            </div>
          </div>

          <div className="div_boneco">
            <img alt="Boneco2" src={Boneco2} className="img_boneco" />
          </div>
        </div>
      </div>

      <a name="resultados" />

      <Footer />
    </>
  );
};

export default EstadoFilter;
